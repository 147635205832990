import '@/styles/base.scss';

import { AppProps } from 'next/app';
import Head from 'next/head';
import LogoContainer from '@/components/logo-container/LogoContainer';
import Navigation from '@/components/navigation/Navigation';
import AdminNavigation from '@/components/admin/navigation/AdminNavigation';
import useConfig from '@/hooks/useConfig';

import { AnimatePresence } from 'framer-motion';
import { useState, useEffect } from 'react';

import { initSentry } from '@/lib/withSentry';

import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
config.autoAddCss = false;

import { initGA, logPageView } from '@/lib/analytics';
import { Router } from 'next/router';

initSentry();

export interface RaspberryStudiosAppProps extends AppProps {
  err?: any;
}

export default function App({ Component, pageProps, err }: RaspberryStudiosAppProps) {
  const { t } = useConfig();
  const [displayCornerImage, setDisplayCornerImage] = useState(true);

  const isLocal = process.env.NEXT_PUBLIC_APP_ENV === 'development';

  if (process.env.NEXT_PUBLIC_GA_TRACKING_ID) {
    useEffect(() => {
      initGA();
      logPageView();
      Router.events.on('routeChangeComplete', logPageView);
    });
  }

  return (
    <>
      <Head>
        <title>{ t('common.site-name') }</title>

        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta name="theme-color" content="#ca3166" />
        <meta httpEquiv="Cache-control" content="public" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <meta httpEquiv="Content-Language" content="en-us" />

        <meta name="robots" content="INDEX,FOLLOW" />

        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content={ t('common.site-name') } />
        <meta property="og:url" content="https://raspberrystudios.co.nz" />
        <meta property="og:type" content="website" />

        <link rel="shortcut icon" type="image/png" href="/images/favicon.png" />
      </Head>

      <main>
        <section className="app">
          <LogoContainer />
          <Navigation />

          <AnimatePresence exitBeforeEnter>
            <Component {...pageProps} setDisplayCornerImage={setDisplayCornerImage} err={err} />
          </AnimatePresence>
        </section>

        {displayCornerImage && <div className="corner-image">
          <img src="/images/corner.png" alt="Aurelia Charm" onContextMenu={() => false} />
        </div>}

        {isLocal && <AdminNavigation />}
      </main>
    </>
  );
}
