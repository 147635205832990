import Link from 'next/link';

import styles from './Navigation.module.scss';
import menu from '@/_content/menu.json';
import { INavigationItem } from './INavigationItem';
import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const availableUrls: string[] = menu.items?.map(item => item.url);

function isActive(url: string): boolean {
  url = url.split(/((?!^)\/|\?|\#)/g)[0];
  // /foo?hello -> /foo
  // /foo#hello -> /foo
  // /foo/bar/baz -> /foo

  const router = useRouter();

  if (url === '/') {
    return router.pathname === url;
  }

  return router.pathname.startsWith(url) && availableUrls.includes(url);
}

function NavigationItem({ item }: { item: INavigationItem }) {
  if (item.external) {
    return (
      <a href={item.url} target="_blank">
        <span>{item.name}</span>
      </a>
    );
  }

  return (
    <Link href={item.url}>
      <a className={isActive(item.url) ? styles.active : ''}>
        <span>{item.name}</span>
      </a>
    </Link>
  );
}

export default function Navigation() {
  const [hamburgerState, setHamburgerState] = useState(false);

  const toggleHamburger = () => setHamburgerState(!hamburgerState);

  const router = useRouter();

  useEffect(() => {
    setHamburgerState(false);
  }, [router.pathname]);

  return (
    <nav className={styles.mainMenu}>
      <div className={[styles.hamburgerToggler, (hamburgerState ? styles.active : '')].join(' ')} onClick={toggleHamburger}>
        <FontAwesomeIcon icon={faBars} />
        <span>Menu</span>
      </div>

      <div className={['mid', styles.items, (hamburgerState ? styles.active : '')].join(' ')}>
        {menu.items && menu.items.map((item: INavigationItem, idx: number) => <NavigationItem item={item} key={idx} />)}
      </div>
    </nav>
  );
}
