import styles from './AdminNavigation.module.scss';

import Link from 'next/link';
import { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faFolder,
  faList,
  faPencilAlt,
  faUserFriends,
  faFont,
  faImages,
  faDollarSign
} from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';

export default function AdminNavigation() {
  const [opened, setOpened] = useState(false);

  const menu = [
    {
      href: '/manage/files',
      tooltip: 'File Manager',
      icon: faFolder
    },
    {
      href: '/manage/menu',
      tooltip: 'Menu',
      icon: faList
    },
    {
      href: '/manage/gallery',
      tooltip: 'Gallery (WIP)',
      icon: faImages,
      inactive: true
    },
    {
      href: '/manage/prices',
      tooltip: 'Prices (WIP)',
      icon: faDollarSign,
      inactive: true
    },
    {
      href: '/manage/blog',
      tooltip: 'Blog (WIP)',
      icon: faPencilAlt,
    },
    {
      href: '/manage/socials',
      tooltip: 'Socials',
      icon: faUserFriends
    },
    {
      href: '/manage/texts',
      tooltip: 'Texts',
      icon: faFont
    }
  ];

  return (
    <nav
      className={[styles.adminMenu, opened ? styles.opened : ''].join(' ')}
      style={{ bottom: `-${10 + menu.length * 68}px` }}
    >
      <div
        className={styles.item}
        onClick={() => setOpened(!opened)}
        data-tip="Management tools"
        data-for="adminmenu"
      >
        <FontAwesomeIcon icon={opened ? faChevronDown : faChevronUp} />
      </div>

      {menu.map((item, idx) => {
        return (
          <Link href={item.href} key={idx}>
            <div
              className={[styles.item, item.inactive ? styles.inactive : ''].join(' ')}
              data-tip={item.tooltip}
              data-for="adminmenu"
            >
              <FontAwesomeIcon icon={item.icon} />
            </div>
          </Link>
        );
      })}

      <ReactTooltip place="right" type="dark" effect="solid" id="adminmenu" uuid="adminmenu" />
    </nav>
  );
}
