import styles from './LogoContainer.module.scss';
import Link from 'next/link';

export default function LogoContainer() {
  return (
    <header className={styles.logoContainer}>
      <Link href="/">
        <a>
          <img src="/images/logo.png" alt="Raspberry Studios" />
        </a>
      </Link>
    </header>
  );
}
